// Global API URL definition
export const apiURL = process.env.REACT_APP_ADMIN_API_URL;

// Locales for the datetime field
export const dateTimeLocales = "cs-CZ";

// Maximum number of items to fetch per page in selects
export const maxPerPage = 50;

// Id of the singular correlation settings record.
// It is a string because React-admin uses the value
// as-is in a PUT request and the backend expects a string.
export const correlationSettingsRecordId = "1";

// Value of the 'active institution' status id.
export const institutionActiveStatusId = "1";
