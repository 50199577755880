import React from "react";
import {Edit, NumberInput, SimpleForm, useTranslate} from "react-admin";

import {correlationSettingsRecordId} from "./constants";
import ExtendedToolbar from "./components/extendedtoolbar";

const CorrelationSettingsEditTitle = () => {
  const translate = useTranslate();
  return <span>{translate("resources.correlationsettings.name")}</span>
}

// staticContext is superfluous, so it is captured here
// so as to not get passed further down
export const CorrelationSettingsEdit = ({staticContext, ...rest}) => (
  <Edit
    id={correlationSettingsRecordId}
    resource="correlationsettings"
    basePath="/correlationsettings"
    title={<CorrelationSettingsEditTitle/>}
    redirect={false}
    {...rest}
  >
    <SimpleForm toolbar={<ExtendedToolbar showSave />}>
      <NumberInput source="radius" min={1}/>
      <NumberInput source="timeWindow" min={1}/>
      <NumberInput source="countThreshold" min={1}/>
    </SimpleForm>
  </Edit>
)