import jsonapiClient from "./jsonapiclient";
import geometryUploadHandler from "./geometryupload";
import { apiURL } from "../constants";

const dataProvider = jsonapiClient(apiURL, {
  updateMethod: "PUT",
  arrayFormat: "repeat",
});

const decoratedDataProvider = geometryUploadHandler(dataProvider);

export default decoratedDataProvider;
