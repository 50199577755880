import React from "react";
import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  EditController,
  EditView,
  FileField,
  FileInput,
  FormDataConsumer,
  FormTab,
  List,
  NumberField,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectArrayInput,
  SelectInput,
  SingleFieldList,
  ShowView,
  ShowController,
  Tab,
  TabbedForm,
  TabbedShowLayout,
  TextField,
  TextInput,
  useTranslate,
} from "react-admin";
import {dateTimeLocales, institutionActiveStatusId, maxPerPage} from "./constants";
import {roles} from "./authprovider/authprovider";
import {
  requiredInputValidator,
  zipCodeInputValidator,
  emailInputValidator,
  phoneNumberInputValidator,
  handlesProtectedAnimalsInputValidator,
} from "./validators";
import LocationInput from "./components/locationinput";
import CustomPagination from "./components/pagination";
import Map from "./components/map";

const InstitutionCreateTitle = () => <span>Nová instituce</span>;
const InstitutionEditTitle = ({record}) => {
  let title = "Detail instituce";
  if (record != null) {
    title += ` ${record.name}`;
  }
  return <span>{title}</span>;
};
const InstitutionShowTitle = ({record}) => {
  const translate = useTranslate();
  let title = translate("resources.institutions.single");
  if (record != null) {
    title += ` ${record.name}`;
  }
  return <span>{title}</span>;
};

const InstitutionFilter = (permissions) => {
  const translate = useTranslate();
  return permissions === roles.SystemAdminRoleID ? [
    <SearchInput
      source="name"
      placeholder={translate("resources.institutions.searchByName")}
      alwaysOn
    />,
    <ReferenceInput
      source="areaId"
      reference="areas"
      perPage={maxPerPage}
      sort={{field: "fullName", order: "ASC"}}
    >
      <SelectInput optionText="fullName"/>
    </ReferenceInput>,
    <ReferenceInput
      source="statusId"
      reference="institutionstatuses"
      sort={{field: "name", order: "ASC"}}
    >
      <SelectInput source="name"/>
    </ReferenceInput>,
    <ReferenceInput
      source="typeId"
      reference="institutiontypes"
      perPage={maxPerPage}
      sort={{field: "name", order: "ASC"}}
    >
      <SelectInput source="name"/>
    </ReferenceInput>
  ] : [];
};

export const InstitutionList = ({permissions, ...props}) => (
  <List filters={InstitutionFilter(permissions)} pagination={<CustomPagination/>} {...props}>
    <Datagrid
      rowClick={permissions <= roles.InstitutionAdminRoleID ? "edit" : "show"}
    >
      <TextField source="id"/>
      <TextField source="name"/>
      <ReferenceField source="areaId" reference="areas">
        <TextField source="fullName"/>
      </ReferenceField>
      <ReferenceField
        source="typeId"
        reference="institutiontypes"
        link={permissions === roles.SystemAdminRoleID ? "edit" : false}
      >
        <TextField source="name"/>
      </ReferenceField>
      <ReferenceField
        source="statusId"
        reference="institutionstatuses"
        link={false}
      >
        <TextField source="name"/>
      </ReferenceField>
    </Datagrid>
  </List>
);

export const InstitutionCreate = (props) => {
  const translate = useTranslate();
  return (
    <Create title={<InstitutionCreateTitle/>} {...props}>
      <TabbedForm redirect="list">
        <FormTab label={translate("tabLabels.general")}>
          <TextInput source="name" validate={requiredInputValidator}/>
          <TextInput source="street" validate={requiredInputValidator}/>
          <TextInput source="zipCode" validate={zipCodeInputValidator}/>
          <TextInput source="city" validate={requiredInputValidator}/>
          <FormDataConsumer>
            {(formDataProps) => <LocationInput {...formDataProps} />}
          </FormDataConsumer>
          <ReferenceInput
            source="typeId"
            reference="institutiontypes"
            validate={requiredInputValidator}
            perPage={maxPerPage}
            sort={{field: "name", order: "ASC"}}
          >
            <SelectInput optionText="name"/>
          </ReferenceInput>
          <ReferenceArrayInput
            source="acceptedHealthStates"
            reference="healthstates"
            sort={{field: "id", order: "ASC"}}
            validate={requiredInputValidator}
          >
            <SelectArrayInput>
              <ChipField source="name"/>
            </SelectArrayInput>
          </ReferenceArrayInput>
        </FormTab>
        <FormTab label={translate("resources.institutions.tabLabels.manager")}>
          <TextInput source="managerFirstName" validate={requiredInputValidator}/>
          <TextInput source="managerLastName" validate={requiredInputValidator}/>
          <TextInput source="managerEmail" validate={emailInputValidator}/>
          <TextInput source="managerPhoneNumber" validate={phoneNumberInputValidator}/>
        </FormTab>
        <FormTab label={translate("tabLabels.settings")}>
          <BooleanInput source="handlesGame"/>
          <BooleanInput source="handlesProtected" initialValue={true} validate={handlesProtectedAnimalsInputValidator}/>
          <BooleanInput source="acceptsDiseaseNotification"/>
        </FormTab>
        <FormTab label={translate("resources.institutions.tabLabels.polygon")}>
          <TextInput source="polygonId"/>
          <FileInput source="geometryData">
            <FileField source="src" title="title"/>
          </FileInput>
        </FormTab>
      </TabbedForm>
    </Create>
  )
};

export const InstitutionEdit = (props) => {
  const translate = useTranslate();
  return (
    <EditController {...props}>
      {(controllerProps) => (
        <EditView title={<InstitutionEditTitle/>} {...props} {...controllerProps}>
          <TabbedForm redirect="list">
            <FormTab label={translate("tabLabels.general")}>
              <NumberField source="id"/>
              <TextInput source="name" validate={requiredInputValidator}/>
              <TextInput source="street" validate={requiredInputValidator}/>
              <TextInput source="zipCode" validate={zipCodeInputValidator}/>
              <TextInput source="city" validate={requiredInputValidator}/>
              <ReferenceInput
                source="areaId"
                reference="areas"
                perPage={maxPerPage}
                sort={{field: "fullName", order: "ASC"}}
                validate={requiredInputValidator}
              >
                <AutocompleteInput optionText="fullName"/>
              </ReferenceInput>
              <ReferenceInput
                source="typeId"
                reference="institutiontypes"
                validate={requiredInputValidator}
                perPage={maxPerPage}
                sort={{field: "name", order: "ASC"}}
              >
                <SelectInput optionText="name"/>
              </ReferenceInput>
              <ReferenceInput
                source="statusId"
                reference="institutionstatuses"
                validate={requiredInputValidator}
              >
                <SelectInput optionText="name"/>
              </ReferenceInput>
              <ReferenceArrayInput
                source="acceptedHealthStates"
                reference="healthstates"
                validate={requiredInputValidator}
                sort={{field: "id", order: "ASC"}}
              >
                <SelectArrayInput>
                  <ChipField source="name"/>
                </SelectArrayInput>
              </ReferenceArrayInput>
              <DateField source="created" locales={dateTimeLocales}/>
              {controllerProps.record && controllerProps.record.updated && (
                <DateField source="updated" locales={dateTimeLocales}/>
              )}
            </FormTab>
            <FormTab label={translate("resources.institutions.tabLabels.manager")}>
              <TextInput
                source="managerFirstName"
                validate={requiredInputValidator}
              />
              <TextInput
                source="managerLastName"
                validate={requiredInputValidator}
              />
              <TextInput source="managerEmail" validate={emailInputValidator}/>
              <TextInput
                source="managerPhoneNumber"
                validate={phoneNumberInputValidator}
              />
            </FormTab>
            <FormTab label={translate("tabLabels.settings")}>
              <BooleanInput source="handlesGame"/>
              <BooleanInput
                source="handlesProtected"
                validate={handlesProtectedAnimalsInputValidator}
              />
              <BooleanInput source="acceptsDiseaseNotification"/>
            </FormTab>
            <FormTab label={translate("resources.institutions.tabLabels.polygon")}>
              <BooleanField source="isPolygonSet"/>
              <TextInput source="polygonId"/>
              <FileInput source="geometryData">
                <FileField source="src" title="title"/>
              </FileInput>
              {controllerProps.record && controllerProps.record.geometry &&
                <Map
                  label={translate("resources.institutions.fields.geometry")}
                  center={controllerProps.record.geometry.center}
                  geometry={
                    {
                      geometry: controllerProps.record.geometry.polygons,
                      active: controllerProps.record.statusId === institutionActiveStatusId
                    }
                  }
                  zoom={13}/>
              }
            </FormTab>
          </TabbedForm>
        </EditView>
      )}
    </EditController>
  )
};

export const InstitutionShow = (props) => {
  const translate = useTranslate();
  return (
    <ShowController {...props}>
      {(controllerProps) => (
        <ShowView
          title={<InstitutionShowTitle/>}
          {...props}
          {...controllerProps}
        >
          <TabbedShowLayout>
            <Tab label={translate("tabLabels.general")}>
              <NumberField source="id"/>
              <TextField source="name"/>
              <TextField source="street"/>
              <TextField source="zipCode"/>
              <TextField source="city"/>
              <ReferenceField
                source="areaId"
                reference="areas"
                perPage={maxPerPage}
                sort={{field: "fullName", order: "ASC"}}
              >
                <TextField source="fullName"/>
              </ReferenceField>
              <ReferenceField
                source="typeId"
                reference="institutiontypes"
                perPage={maxPerPage}
                sort={{field: "name", order: "ASC"}}
              >
                <TextField source="name"/>
              </ReferenceField>

              <ReferenceField
                source="statusId"
                reference="institutionstatuses"
                link={false}
              >
                <TextField source="name"/>
              </ReferenceField>
              <ReferenceArrayField
                source="acceptedHealthStates"
                reference="healthstates"
                link={false}
              >
                <SingleFieldList>
                  <ChipField source="name"/>
                </SingleFieldList>
              </ReferenceArrayField>
              <DateField source="created" locales={dateTimeLocales}/>
              {controllerProps.record && controllerProps.record.updated && (
                <DateField source="updated" locales={dateTimeLocales}/>
              )}
            </Tab>
            <Tab label={translate("resources.institutions.tabLabels.manager")}>
              <TextField source="managerFirstName"/>
              <TextField source="managerLastName"/>
              <TextField source="managerEmail"/>
              <TextField source="managerPhoneNumber"/>
            </Tab>
            <Tab label={translate("tabLabels.settings")}>
              <BooleanField source="handlesGame"/>
              <BooleanField source="handlesProtected"/>
              <BooleanField source="acceptsDiseaseNotification"/>
            </Tab>
            <Tab label={translate("resources.institutions.tabLabels.polygon")}>
              <BooleanField source="isPolygonSet" />
              <TextField source="polygonId"/>
              {controllerProps.record && controllerProps.record.geometry &&
                <Map
                  label={translate("resources.institutions.fields.geometry")}
                  center={controllerProps.record.geometry.center}
                  geometry={
                    {
                      geometry: controllerProps.record.geometry.polygons,
                      active: controllerProps.record.statusId === institutionActiveStatusId
                    }
                  }
                  zoom={13}/>
              }
            </Tab>
          </TabbedShowLayout>
        </ShowView>
      )}
    </ShowController>
  )
};
