import React from "react";
import { AppBar } from "react-admin";
import AdminUserMenu from "./adminusermenu";

const AdminAppBar = props => (
  <AppBar
    {...props}
    userMenu={<AdminUserMenu userName={localStorage.getItem("name")} />}
  />
);
export default AdminAppBar;
