import React from "react";
import { SvgIcon } from "@material-ui/core";

function CorrelationIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M 7.0641716,0.38 H 1.7170992 c -0.73522244,0 -1.33008425,0.6015457 -1.33008425,1.3367681 l -0.006684,10.6941439 c 0,0.735223 0.59486182,1.336769 1.33008435,1.336769 h 8.0272925 c 0.7352222,0 1.3367682,-0.601546 1.3367682,-1.336769 V 4.3903044 Z M 8.4009398,11.074144 H 3.0538675 V 9.7373762 h 5.3470723 z m 0,-2.6735359 H 3.0538675 V 7.06384 H 8.4009398 Z M 6.3957878,5.0586886 V 1.3825761 L 10.0719,5.0586886 Z" />
      <path d="m 19.622857,0.38126305 h -5.347073 c -0.735222,0 -1.330084,0.6015457 -1.330084,1.33676815 L 12.939,12.412175 c 0,0.735223 0.594862,1.336769 1.330084,1.336769 h 8.027293 c 0.735222,0 1.336768,-0.601546 1.336768,-1.336769 V 4.3915675 Z M 20.959625,11.075407 H 15.612552 V 9.738639 h 5.347073 z m 0,-2.673536 H 15.612552 V 7.065103 h 5.347073 z M 18.954473,5.0599522 v -3.676113 l 3.676112,3.676113 z" />
      <path d="m 5.7267925,14.541016 c -1.190509,-9.86e-4 -2.1561567,0.963787 -2.15625,2.154296 0.00239,0.896154 0.5592675,1.69725 1.3984375,2.011719 l -0.00586,3.472657 c -0.00104,0.414959 0.3350398,0.751913 0.75,0.751953 H 18.300781 c 0.414196,-4.1e-5 0.749959,-0.335804 0.75,-0.75 v -3.458985 c 0.854537,-0.308051 1.42406,-1.118978 1.423828,-2.027344 -9.4e-5,-1.189746 -0.964551,-2.154203 -2.154297,-2.154296 -1.189746,9.4e-5 -2.154202,0.96455 -2.154296,2.154296 0.0019,0.891274 0.552308,1.689399 1.384765,2.007813 v 2.728516 H 6.4650737 L 6.46898,18.71875 C 7.3175518,18.407287 7.8814701,17.599238 7.8810894,16.695312 7.8809957,15.505566 6.9165388,14.541109 5.7267925,14.541016 Z" />
    </SvgIcon>
  );
}

export default CorrelationIcon;
