import React from "react";
import {
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
  useTranslate,
} from "react-admin";

const CommunicationMethodShowTitle = ({ record }) => {
  const translate = useTranslate();
  let title = translate.translate("resources.communicationmethods.single");
  if (record != null) {
    title += ` ${record.name}`;
  }
  return <span>{title}</span>;
};

export const CommunicationMethodShow = (props) => (
  <Show title={<CommunicationMethodShowTitle />} {...props}>
    <SimpleShowLayout>
      <NumberField source="id" />
      <TextField source="name" />
    </SimpleShowLayout>
  </Show>
);
