import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Container,
  Button,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import { email } from "react-admin";
import Header from "./header";
import { apiURL } from "../constants";
import theme from "../theme/theme";

const useStyles = makeStyles({
  topLevelContainerBox: {
    position: "absolute",
    left: "0",
    top: "0",
    bottom: "0",
    right: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  emailQueryForm: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "66%",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "33%",
    },
    backgroundColor: "paper",
    border: "solid",
    borderColor: grey[300],
    borderRadius: "6px",
    borderWidth: "1px",
    padding: "15px",
    verticalAlign: "center",
  },
  sendButtonContainerBox: {
    marginTop: "20px",
  },
  sendButton: {
    float: "right",
  },
});

const GetEmail = () => {
  const [emailAddress, setEmailAddress] = useState();
  const [errorText, setErrorText] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [done, setDone] = useState(false);

  const handleChange = (event) => {
    setEmailAddress(event.target.value);
    setErrorText(undefined);
  };

  const handleSubmit = (event) => {
    const result = email("Vstup neodpovídá platné e-mailové adrese.")(
      emailAddress
    );

    if (result !== undefined) {
      setErrorText(result.message);
    } else {
      setErrorText();
      setSubmitted(true);
    }

    event.preventDefault();
  };

  useEffect(() => {
    if (submitted) {
      setSubmitted(false);
      axios
        .post(apiURL + "/accountrecovery", {
          emailAddress,
        })
        .then(() => {
          setDone(true);
        })
        .catch((error) => {
          setErrorText(error.message);
        });
    }
  }, [submitted, emailAddress]);

  const classes = useStyles();
  return done ? (
    <Redirect to="/emailsent" />
  ) : (
    <ThemeProvider theme={theme}>
      <Grid container direction="column">
        <Header title="Zapomněli jste heslo?" />
      </Grid>
      <Box className={classes.topLevelContainerBox}>
        <Container className={classes.emailQueryForm}>
          <form onSubmit={handleSubmit}>
            <Typography variant="body2" paragraph>
              Do pole níže zadejte Vaši e-mailovou adresu. Bude Vám na ni zaslán
              odkaz na stránku, kde si nastavíte nové.
            </Typography>
            {errorText !== undefined ? (
              <TextField
                required
                fullWidth
                error
                helperText={errorText}
                label="Vaše e-mailová adresa"
                onChange={handleChange}
              />
            ) : (
              <TextField
                required
                fullWidth
                label="Vaše e-mailová adresa"
                onChange={handleChange}
              />
            )}
            <Box className={classes.sendButtonContainerBox}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.sendButton}
              >
                Odeslat
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default GetEmail;
