// System resources
import React from "react";
import { Route } from "react-router-dom";
import { Admin, Resource } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";

// Icons
import {
  Description as DescriptionIcon,
  Home as HomeIcon,
  HomeWork as HomeWorkIcon,
  PeopleAlt as PeopleAltIcon,
  Room as RoomIcon,
  SupervisorAccount as SupervisorAccountIcon
} from "@material-ui/icons";
import RegionsIcon from "./icons/regions";

// Local resources
import authProvider, { roles } from "./authprovider/authprovider";

import { ReportEdit, ReportList } from "./reports";
import { HealthStateShow } from "./healthstates";
import { ReportStatusShow } from "./reportstatuses";
import {
  InstitutionTypeCreate,
  InstitutionTypeEdit,
  InstitutionTypeList,
  InstitutionTypeShow,
} from "./institutiontypes";
import { InstitutionStatusShow } from "./institutionstatuses";
import {
  InstitutionCreate,
  InstitutionEdit,
  InstitutionList,
  InstitutionShow,
} from "./institutions";
import { CommunicationMethodShow } from "./communicationmethods";
import { AreaCreate, AreaEdit, AreaList, AreaShow } from "./areas";
import { RegionCreate, RegionEdit, RegionList, RegionShow } from "./regions";
import {
  ContactPersonList,
  ContactPersonEdit,
  ContactPersonCreate,
} from "./contactpersons";
import { UserCreate, UserEdit, UserList } from "./users";
import { UserRoleShow } from "./roles";
import AccountEdit from "./account";
import { CorrelationSettingsEdit } from "./correlationsettings";
import { HuntingGroundGeometriesList } from "./hggeometries";

import AdminLayout from "./components/adminlayout";
import theme from "./theme/theme";

import dataProvider from "./dataprovider/dataprovider";

import englishMessages from "ra-language-english";
import czechMessages from "./l10n/translations";

import { NotificationStateShow } from "./notificationstates";

import LoginPage from "./components/login";
import GetEmail from "./accountrecovery/getemail";
import EmailSent from "./accountrecovery/emailsent";
import NewPassword from "./accountrecovery/newpassword";
import PasswordChanged from "./accountrecovery/passwordchanged";

const messages = {
  en: englishMessages,
  cs: czechMessages,
};
const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "cs");

const customRoutes = [
  <Route key="account" path="/account" component={AccountEdit} />,
  <Route key="correlationsettings" path="/correlationsettings" component={CorrelationSettingsEdit} />,
  // <Route key="hggeometries" path="/hggeometries" component={HuntingGroundGeometriesList} />,
  <Route exact key="forgot" path="/forgot" component={GetEmail} noLayout />,
  <Route key="emailsent" path="/emailsent" component={EmailSent} noLayout />,
  <Route
    key="newpassword"
    path="/newpassword"
    component={NewPassword}
    noLayout
  />,
  <Route
    key="passwordchanged"
    path="/passwordchanged"
    component={PasswordChanged}
    noLayout
  />,
];

const App = (props) => (
  <Admin
    authProvider={authProvider}
    dataProvider={dataProvider}
    i18nProvider={i18nProvider}
    customRoutes={customRoutes}
    loginPage={LoginPage}
    layout={AdminLayout}
    theme={theme}
    {...props}
  >
    {(permissions) => [
      <Resource name="account" />,
      <Resource
        name="reports"
        list={ReportList}
        edit={ReportEdit}
        icon={DescriptionIcon}
      />,
      <Resource name="healthstates" show={HealthStateShow} />,
      <Resource name="reportstatuses" show={ReportStatusShow} />,
      <Resource name="notificationstates" show={NotificationStateShow} />,
      <Resource
        name="institutions"
        icon={HomeIcon}
        list={InstitutionList}
        edit={
          permissions <= roles.InstitutionAdminRoleID ? InstitutionEdit : null
        }
        create={
          permissions === roles.SystemAdminRoleID ? InstitutionCreate : null
        }
        show={InstitutionShow}
      />,
      <Resource
        name="institutiontypes"
        icon={HomeWorkIcon}
        list={
          permissions === roles.SystemAdminRoleID ? InstitutionTypeList : null
        }
        create={
          permissions === roles.SystemAdminRoleID ? InstitutionTypeCreate : null
        }
        edit={
          permissions === roles.SystemAdminRoleID ? InstitutionTypeEdit : null
        }
        show={InstitutionTypeShow}
      />,
      <Resource name="institutionstatuses" show={InstitutionStatusShow} />,
      <Resource name="communicationmethods" show={CommunicationMethodShow} />,
      <Resource
        name="contactpersons"
        icon={PeopleAltIcon}
        list={
          permissions <= roles.InstitutionAdminRoleID ? ContactPersonList : null
        }
        edit={
          permissions <= roles.InstitutionAdminRoleID ? ContactPersonEdit : null
        }
        create={
          permissions <= roles.InstitutionAdminRoleID
            ? ContactPersonCreate
            : null
        }
      />,
      <Resource
        name="areas"
        icon={RoomIcon}
        list={AreaList}
        create={permissions === roles.SystemAdminRoleID ? AreaCreate : null}
        edit={permissions === roles.SystemAdminRoleID ? AreaEdit : null}
        show={AreaShow}
      />,
      <Resource
        name="regions"
        icon={RegionsIcon}
        list={RegionList}
        create={permissions === roles.SystemAdminRoleID ? RegionCreate : null}
        edit={permissions === roles.SystemAdminRoleID ? RegionEdit : null}
        show={RegionShow}
      />,
      permissions <= roles.InstitutionAdminRoleID ? (
        <Resource
          name="users"
          icon={SupervisorAccountIcon}
          create={UserCreate}
          edit={UserEdit}
          list={UserList}
        />
      ) : null,
      <Resource name="roles" show={UserRoleShow} />,
      permissions === roles.SystemAdminRoleID ? (
        <Resource name="correlationsettings" edit={CorrelationSettingsEdit} />
      ) : null,
      permissions === roles.SystemAdminRoleID ? (
        <Resource name="hggeometries" list={HuntingGroundGeometriesList} />
      ) : null,
    ]}
  </Admin>
);

export default App;
