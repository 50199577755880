import React from "react";
import {
  NumberField,
  TextField,
  SimpleShowLayout,
  Show,
  useTranslate,
} from "react-admin";

const HealthStateShowTitle = ({ record }) => {
  const translate = useTranslate();
  let title = translate("resources.healthstates.single");
  if (record != null) {
    title += ` ${record.name}`;
  }
  return <span>{title}</span>;
};

export const HealthStateShow = (props) => (
  <Show title={<HealthStateShowTitle />} {...props}>
    <SimpleShowLayout>
      <NumberField source="id" />
      <TextField source="name" />
    </SimpleShowLayout>
  </Show>
);
