import React from "react";
import {UserMenu, MenuItemLink, useTranslate} from "react-admin";
import SettingsIcon from "@material-ui/icons/Settings";

const AdminUserMenu = ({userName, ...props}) => {
  const translate = useTranslate();

  return (
    <UserMenu
      label={userName ? `Přihlášen jako ${userName}` : "Nepřihlášen"}
      {...props}
    >
      <MenuItemLink
        to="/account"
        primaryText={translate("resources.account.name")}
        leftIcon={<SettingsIcon/>}
      />
    </UserMenu>
  );
}

export default AdminUserMenu;
