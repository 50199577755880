import React from "react";
import {
  Create,
  Datagrid,
  DateField,
  EditController,
  EditView,
  List,
  NumberField,
  SearchInput,
  ShowController,
  ShowView,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useTranslate,
} from "react-admin";
import { requiredInputValidator } from "./validators";
import { roles } from "./authprovider/authprovider";
import { dateTimeLocales } from "./constants";
import CustomPagination from "./components/pagination";

const RegionCreateTitle = () => <span>Nový region</span>;

const RegionEditTitle = ({ record }) => {
  let title = "Detail regionu";
  if (record != null) {
    title += ` ${record.name}`;
  }
  return <span>{title}</span>;
};

const RegionShowTitle = ({ record }) => {
  const translate = useTranslate();
  let title = translate("resources.regions.single");
  if (record != null) {
    title += ` ${record.name}`;
  }
  return <span>{title}</span>;
};

const RegionFilter = () => {
  const translate = useTranslate();
  return [
    <SearchInput
      source="name"
      placeholder={translate("resources.regions.searchByName")}
      alwaysOn
    />
  ]
};

export const RegionList = ({ permissions, ...rest }) => (
  <List filters={RegionFilter()} pagination={<CustomPagination/>} {...rest}>
    <Datagrid
      rowClick={permissions === roles.SystemAdminRoleID ? "edit" : "show"}
    >
      <TextField source="id" />
      <TextField source="name" />
    </Datagrid>
  </List>
);

export const RegionEdit = (props) => (
  <EditController {...props}>
    {(controllerProps) => (
      <EditView title={<RegionEditTitle />} {...props} {...controllerProps}>
        <SimpleForm redirect="list">
          <NumberField source="id" />
          <TextInput source="name" validate={requiredInputValidator} />
          <DateField source="created" locales={dateTimeLocales} />
          {controllerProps.record && controllerProps.record.updated && (
            <DateField source="updated" locales={dateTimeLocales} />
          )}
        </SimpleForm>
      </EditView>
    )}
  </EditController>
);

export const RegionCreate = (props) => (
  <Create title={<RegionCreateTitle />} {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" validate={requiredInputValidator} />
    </SimpleForm>
  </Create>
);

export const RegionShow = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <ShowView title={<RegionShowTitle />} {...props} {...controllerProps}>
        <SimpleShowLayout>
          <NumberField source="id" />
          <TextField source="name" validate={requiredInputValidator} />
          <DateField source="created" locales={dateTimeLocales} />
          {controllerProps.record && controllerProps.record.updated && (
            <DateField source="updated" locales={dateTimeLocales} />
          )}
        </SimpleShowLayout>
      </ShowView>
    )}
  </ShowController>
);
