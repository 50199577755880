const czechMessages = {
  Unauthorized:
    "Byly zadány neplatné přihlašovací údaje, nebo vypršela platnost přihlášení.",
  auth: {
    invalid_credentials: "Neplatné přihlašovací údaje",
  },
  tabLabels: {
    general: "Obecné",
    settings: "Nastavení"
  },
  ra: {
    action: {
      delete: "Smazat",
      show: "Zobrazit",
      list: "Seznam",
      save: "Uložit",
      create: "Vytvořit",
      edit: "Upravit",
      sort: "Seřadit",
      cancel: "Zrušit",
      undo: "Vrátit",
      refresh: "Obnovit",
      add: "Přidat",
      remove: "Odstranit",
      add_filter: "Přidat filtr",
      remove_filter: "Zrušit filtr",
      back: "Zpět",
      bulk_actions:
        "Vybrán %{smart_count} záznam |||| Vybrány %{smart_count} záznamy |||| Vybráno %{smart_count} záznamů",
      export: "Exportovat",
      clear_input_value: "Vymazat",
      search: "Hledat",
      unselect: "Zrušit výběr",
    },
    auth: {
      user_menu: "Uživatelské menu",
      username: "Uživatelské jméno",
      password: "Heslo",
      sign_in: "Přihlásit se",
      sign_in_error: "Ověření selhalo, zkuste to znovu",
      logout: "Odhlásit se",
      auth_check_error: "Chyba ověření přihlášení",
      forgot_password: "Zapomněli jste heslo?",
      invalid_credentials: "Neplatné přihlašovací údaje",
    },
    boolean: {
      true: "Ano",
      false: "Ne",
    },
    input: {
      file: {
        upload_several:
          "Přetáhněte soubory pro nahrání nebo klikněte pro výběr",
        upload_single:
          "Přetáhněte .shp soubor pro nahrání nebo klikněte pro jeho výběr",
      },
      image: {
        upload_several:
          "Přetáhněte obrázky pro nahrání nebo klikněte pro výběr",
        upload_single:
          "Přetáhněte obrázek pro nahrání nebo klikněte pro jeho výběr",
      },
      references: {
        all_missing: "Nemohu nalézt odkazovaná data",
        many_missing:
          "Minimálně jedna z odkazovaných položek již není dostupná",
        single_missing: "Odkazovaná položka již není dostupná",
      },
      password: {
        toggle_hidden: "Skrýt heslo",
        toggle_visible: "Odhalit heslo",
      },
    },
    message: {
      yes: "Ano",
      no: "Ne",
      are_you_sure: "Jste si jisti?",
      about: "O aplikaci",
      not_found: "Nic nebylo nalezeno",
      loading: "Stránka se načítá, hned to bude...",
      invalid_form:
        "Data ve formuláři nejsou platná. Prosím zkontrolujte zadané hodnoty.",
      delete_title: "Smazat %{name} #%{id}",
      delete_content: "Jste si jisti, že chcete smazat tento záznam?",
      bulk_delete_title:
        "Smazat %{name} |||| Smazat %{smart_count} %{name} položek",
      bulk_delete_content:
        "Jste si jisti, že chcete smazat %{name}? |||| Jste si jisti, že chcete smazat tyto %{smart_count} položky? |||| Jste si jisti, že chcete smazat těchto %{smart_count} položek?",
      error: "Došlo k chybě",
      details: "Podrobnosti o vzniklé chybě",
    },
    navigation: {
      no_results: "Nenalezen žádný záznam",
      no_more_results:
        "Stránka číslo %{page} je mimo rozsah. Zkuste předchozí.",
      page_out_of_boundaries: "Stránka číslo %{page} je mimo rozsah",
      page_out_from_end: "Nelze se přepnout za poslední stranu",
      page_out_from_begin: "Nelze se přepnout před první stranu",
      page_range_info: "%{offsetBegin}-%{offsetEnd} z %{total}",
      page_rows_per_page: "Položek na stránku",
      next: "Další",
      prev: "Předchozí",
      skip_nav: "Přejít na obsah",
    },
    notification: {
      updated:
        "Záznam aktualizován |||| %{smart_count} záznamy aktualizovány |||| %{smart_count} záznamů aktualizováno",
      created: "Záznam vytvořen",
      deleted: "Záznam smazán |||| %{smart_count} prvků smazáno",
      bad_item: "Nesprávný záznam",
      item_doesnt_exist: "Záznam neexistuje",
      http_error: "Chyba komunikace serveru",
      canceled: "Akce zrušena",
      logged_out: "Odhlášen",
    },
    page: {
      list: "%{name}",
      edit: "%{name} #%{id}",
      error: "Chyba",
      show: "%{name} #%{id}",
      create: "Vytvořit %{name}",
      dashboard: "Dashboard",
      not_found: "Nenalezeno",
      loading: "Načítání",
      empty: "Nenalezen žádný záznam",
      invite: 'Kliknutím na "Vytvořit" můžete vytvořit nový záznam',
    },
    validation: {
      required: "Povinné pole",
      minLength: "Musí obsahovat nejméně %{min} znaků",
      maxLength: "Může obsahovat nejvýše %{max} znaků",
      minValue: "Musí být alespoň %{min}",
      maxValue: "Může být nejvýše %{max}",
      number: "Musí být číslo",
      email: "Musí být platná e-mailová adresa",
      oneOf: "Musí být jednou z těchto hodnot: %{options}",
      regex: "Musí být ve specifickém formátu (regexp): %{pattern}",
    },
  },
};

const reports = {
  name: "Hlášení",
  single: "Hlášení",
  searchAnimal: "Hledat zvíře",
  fields: {
    id: "Identifikační číslo",
    source: "Zdroj hlášení",
    animalName: "Název zvířete",
    healthStateId: "Zdravotní stav",
    reportStatusId: "Stav hlášení",
    longitude: "Zeměpisná délka (WGS84)",
    latitude: "Zeměpisná šířka (WGS84)",
    accuracy: "Přesnost zaměření (m)",
    isGame: "Lovná zvěř",
    areaId: "Oblast nálezu",
    message: "Doplňující zpráva",
    created: "Vytvořeno",
    received: "Přijato",
    updated: "Aktualizováno",
    photoUrl: "Doprovodná fotografie",
    notifications: "Odeslaná oznámení",
    createdFrom: "Vytvořeno od",
    createdTo: "Vytvořeno do",
    updatedFrom: "Aktualizováno od",
    updatedTo: "Aktualizováno do",
    receivedFrom: "Přijato od",
    receivedTo: "Přijato do",
    location: "Mapový náhled lokality nálezu"
  },
  tabLabels: {
    photo: "Fotodokumentace",
    location: "Lokalita",
    notifications: "Oznámení"
  },
  popupLabels: {
    reportLocation: "Místo nálezu"
  },
  staticText: {
    noPhoto: "Fotodokumentace není k dispozici"
  }
};

const healthStates = {
  name: "Zdravotní stavy",
  single: "Zdravotní stav",
  fields: {
    id: "Identifikační číslo",
    name: "Popis",
  },
};

const reportStatuses = {
  name: "Stavy hlášení",
  single: "Stav hlášení",
  fields: {
    id: "Identifikační číslo",
    name: "Popis",
  },
};

const institutions = {
  name: "Instituce",
  single: "Instituce",
  searchByName: "Hledat dle jména",
  fields: {
    id: "Identifikační číslo",
    name: "Název",
    status: "Stav",
    street: "Ulice a č.p.",
    zipCode: "PSČ",
    city: "Město",
    typeId: "Typ",
    managerFirstName: "Jméno vedoucího pracovníka",
    managerLastName: "Příjmení vedoucího pracovníka",
    managerEmail: "E-mail vedoucího pracovníka",
    managerPhoneNumber: "Telefon vedoucího pracovníka",
    acceptedHealthStates: "Zdravotní stavy přijímaných zvířat",
    areaId: "Oblast",
    statusId: "Stav",
    created: "Vytvořeno",
    updated: "Aktualizováno",
    polygonId: "Identifikátor polygonu",
    isPolygonSet: "Má nastavený polygon?",
    geometryData: "Zdrojový shapefile (multi)polygonu nové spádové oblasti",
    geometry: "Náhled současného polygonu spádové oblasti",
    handlesGame: "Zpracovává lovnou zvěř",
    handlesProtected: "Zpracovává chráněnou zvěř",
    acceptsDiseaseNotification: "Přijímá oznámení o možných výskytech ohnisek nákaz"
  },
  tabLabels: {
    manager: "Vedoucí pracovník",
    polygon: "Polygon"
  }
};

const institutionTypes = {
  name: "Typy institucí",
  single: "Typ instituce",
  searchByName: "Hledat dle jména",
  fields: {
    id: "Identifikační číslo",
    name: "Název",
    isSystem: "Systémová",
    isReadOnly: "Pouze pro čtení",
    created: "Vytvořeno",
    updated: "Aktualizováno",
  },
};

const institutionStatuses = {
  name: "Stavy institucí",
  single: "Stav instituce",
  fields: {
    id: "Identifikační číslo",
    name: "Název",
  },
};

const communicationMethods = {
  name: "Metody komunikace",
  single: "Metoda komunikace",
  fields: {
    id: "Identifikační číslo",
    name: "Název",
  },
};

const contactPersons = {
  name: "Kontaktní osoby",
  single: "Kontaktní osoba",
  otherInstitution: "Osoba z jiné instituce",
  fields: {
    id: "Identifikační číslo",
    title: "Titul",
    firstName: "Jméno",
    lastName: "Příjmení",
    gender: "Pohlaví",
    institutionId: "Instituce",
    email: "E-mailová adresa",
    phoneNumber: "Telefonní číslo",
    workingHours: "Pracovní doba",
    start: "Počátek",
    end: "Konec",
    days: "Dny platnosti",
    created: "Vytvořeno",
    updated: "Aktualizováno",
    preferredCommunicationMethodId: "Preferovaná metoda komunikace",
  },
  tabLabels: {
    workingHours: "Pracovní doba"
  }
};

const areas = {
  name: "Oblasti",
  single: "Oblast",
  fields: {
    id: "Identifikační číslo",
    shortName: "Zkrácené jméno",
    fullName: "Plné jméno",
    isPolygonSet: "Má nastavený polygon?",
    polygonData: "Zdrojový shapefile nového polygonu",
    geometry: "Náhled současného polygonu oblasti",
    created: "Vytvořeno",
    updated: "Aktualizováno",
  },
  tabLabels: {
    polygon: "Polygon"
  }
};

const regions = {
  name: "Regiony",
  single: "Region",
  searchByName: "Hledat dle jména",
  fields: {
    id: "Identifikační číslo",
    name: "Název",
    created: "Vytvořeno",
    updated: "Aktualizováno",
  },
};

const users = {
  name: "Uživatelé",
  single: "Uživatel",
  fields: {
    id: "Identifikační číslo",
    firstName: "Jméno",
    lastName: "Příjmení",
    roleId: "Role v systému",
    institutionId: "Instituce",
    loginName: "Přihlašovací jméno",
    newPassword: "Nové heslo",
    newPasswordConfirm: "Potvrzení nového hesla",
    currentPassword: "Současné heslo pro ověření",
    email: "E-mailová adresa",
    phoneNumber: "Telefonní číslo",
    created: "Datum vytvoření účtu",
    gender: "Pohlaví",
  },
};

const roles = {
  name: "Uživatelské role",
  single: "Uživatelská role",
  fields: {
    id: "Identifikační číslo",
    name: "Název",
  },
};

const account = {
  name: "Nastavení účtu",
  fields: {
    id: "Identifikační číslo",
    loginName: "Přihlašovací jméno",
    roleId: "Role v systému",
    institutionId: "Instituce",
    firstName: "Jméno",
    lastName: "Příjmení",
    email: "E-mailová adresa",
    phoneNumber: "Telefonní číslo",
    currentPassword: "Současné heslo pro ověření",
    password: "Nové heslo",
    passwordConfirm: "Potvrzení nového hesla",
  },
  notes: {
    systemAdminAccount: "Tento účet je systémový a nelze jej smazat",
    nonSystemAdminAccount: "Pouze správce může smazat Váš účet"
  }
};

const notificationStates = {
  name: "Stavy oznámení",
  single: "Stav oznámení",
  fields: {
    id: "Identifikační číslo",
    name: "Název",
  },
};

const correlationSettings = {
  name: "Korelace hlášení",
  fields: {
    radius: "Velikost akční oblasti (m)",
    timeWindow: "Délka časového okna (dnů)",
    countThreshold: "Mezní počet hlášení"
  },
};

const huntingGroundGeometries = {
  name: "Polygony honiteb",
  simplificationWarning: "POZNÁMKA: Zobrazené polygony byly pouze pro potřeby vizualizace mírně zjednodušeny za účelem snížení výpočetních nároků. V databázi jsou ve své původní formě."
}

const recordSettings = {
  isSystem: "Záznam je označen jako systémový a nelze jej proto smazat.",
  isReadOnly: "Záznam je označen pouze pro čtení a nelze jej proto upravit.",
  isBoth: "Záznam je označen jako systémový a pouze pro čtení a nelze jej proto upravit nebo smazat.",
};

czechMessages.resources = {
  reports: reports,
  healthstates: healthStates,
  reportstatuses: reportStatuses,
  institutions: institutions,
  institutiontypes: institutionTypes,
  institutionstatuses: institutionStatuses,
  communicationmethods: communicationMethods,
  contactpersons: contactPersons,
  areas: areas,
  regions: regions,
  users: users,
  roles: roles,
  account: account,
  notificationstates: notificationStates,
  correlationsettings: correlationSettings,
  hggeometries: huntingGroundGeometries,
  recordsettings: recordSettings,
};

export default czechMessages;
