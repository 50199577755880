import React from "react";
import {
  BooleanField,
  Create,
  Datagrid,
  DateField,
  EditController,
  EditView,
  List,
  NumberField,
  SearchInput,
  ShowController,
  ShowView,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useTranslate,
} from "react-admin";

import {requiredInputValidator} from "./validators";
import {dateTimeLocales} from "./constants";
import ExtendedToolbar from "./components/extendedtoolbar";

const InstitutionTypeCreateTitle = () => <span>Nový typ instituce</span>;

const InstitutionTypeEditTitle = ({record}) => {
  let title = "Detail typu instituce";
  if (record != null) {
    title += ` ${record.name}`;
  }
  return <span>{title}</span>;
};

const InstitutionTypeShowTitle = ({record}) => {
  const translate = useTranslate();
  let title = translate("resources.institutiontypes.single");
  if (record != null) {
    title += ` ${record.name}`;
  }
  return <span>{title}</span>;
};

const SpecialRecordSettingsNote = (translate, record) => {
  if (record.isSystem && record.isReadOnly) {
    return translate("resources.recordsettings.isBoth");
  } else if (record.isSystem) {
    return translate("resources.recordsettings.isSystem");
  } else if (record.isReadOnly) {
    return translate("resources.recordsettings.isReadOnly");
  }

  return "";
};

const InstitutionTypeFilter = () => {
  const translate = useTranslate();
  return [
    <SearchInput
      source="name"
      placeholder={translate("resources.institutiontypes.searchByName")}
      alwaysOn
    />
  ]
};

export const InstitutionTypeShow = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <ShowView title={<InstitutionTypeShowTitle/>} {...props} {...controllerProps}>
        <SimpleShowLayout>
          <NumberField source="id"/>
          <TextField source="name"/>
          <DateField source="created" locales={dateTimeLocales} showTime/>
          {controllerProps.record && controllerProps.record.updated && (
            <DateField source="updated" locales={dateTimeLocales} showTime/>
          )}
        </SimpleShowLayout>
      </ShowView>
    )}
  </ShowController>
);

export const InstitutionTypeList = (props) => (
  <List filters={InstitutionTypeFilter()} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id"/>
      <TextField source="name"/>
      <BooleanField source="isSystem" />
      <BooleanField source="isReadOnly" />
    </Datagrid>
  </List>
);

export const InstitutionTypeEdit = (props) => {
  const translate = useTranslate();
  return (
    <EditController {...props}>
      {(controllerProps) => controllerProps.record?.isReadOnly ?? false ? (
        <EditView title={<InstitutionTypeEditTitle/>} {...controllerProps}>
          <SimpleForm toolbar={<ExtendedToolbar noteText={SpecialRecordSettingsNote(translate, controllerProps.record)}/>}>
            <NumberField source="id"/>
            <TextField source="name"/>
            <DateField source="created" locales={dateTimeLocales}/>
            {controllerProps.record && controllerProps.record.updated && (
              <DateField source="updated" locales={dateTimeLocales}/>
            )}
          </SimpleForm>
        </EditView>
      ) : (
        <EditView title={<InstitutionTypeEditTitle/>} {...controllerProps}>
          <SimpleForm redirect="list">
            <NumberField source="id"/>
            <TextInput source="name" validate={requiredInputValidator}/>
            <DateField source="created" locales={dateTimeLocales}/>
            {controllerProps.record && controllerProps.record.updated && (
              <DateField source="updated" locales={dateTimeLocales}/>
            )}
          </SimpleForm>
        </EditView>
      )}
    </EditController>
  )
};

export const InstitutionTypeCreate = (props) => (
  <Create title={<InstitutionTypeCreateTitle/>} {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" validate={requiredInputValidator}/>
    </SimpleForm>
  </Create>
);
