import React from "react";
import { Layout } from "react-admin";
import AdminAppBar from "./adminappbar";
import Menu from "./menu";

const AdminLayout = (props) => (
  <Layout {...props} appBar={AdminAppBar} menu={Menu} />
);

export default AdminLayout;
