import React, {Fragment, useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import {Labeled} from "react-admin";
import {Map as LeafletMap, Marker, Polygon, Popup, TileLayer} from "react-leaflet";
import {Link} from "@material-ui/core";

// Points to CZU's campus location
const defaultCenter = [50.1302775, 14.3734506]

const defaultZoom = 16;
const minZoomWithMarker = 13;

const getStyles = overrides => makeStyles({
  leafletContainer: {
    width: "100%",
    height: "500px",        // Setting up height is necessary
    marginBottom: "40px",   // This helps with putting some space between the edge of the map view and the bottom of the form in the Show component
    ...overrides
  }
});

const GeometryColor = active => {
  if (active === undefined) {
    return "blue";
  }

  return active ? "green" : "red";
}

const MapComponent = props => {
  const { classes, center, zoom, marker, geometry, geometryFetcher } = props;
  const [bounds, setBounds] = useState({});
  const [polygons, setPolygons] = useState([]);
  const [currentZoom, setCurrentZoom] = useState(zoom || defaultZoom);
  const mapRef = useRef();
  const isMountedRef = useRef(false);

  // Handler to update bounds in the state to force
  // fetch of new geometries and a redraw
  const onMoveEnd = () => {
    //console.log("onMoveEnd start");
    if (!mapRef.current || !geometryFetcher) {
      //console.log("onMoveEnd premature end");
      return;
    }

    const newBounds = mapRef.current.leafletElement.getBounds();

    // This prevents state change if this callback is called
    // but the bounding box values remain the same
    if (bounds._southWest &&
        bounds._southWest.lng === newBounds._southWest.lng &&
        bounds._southWest.lat === newBounds._southWest.lat &&
        bounds._northEast.lng === newBounds._northEast.lng &&
        bounds._northEast.lat === newBounds._northEast.lat) {
      // console.log("onMoveEnd premature end");
      return;
    }

    setBounds(newBounds);

    // console.log("onMoveEnd end");
  }


  const onZoomEnd = () => {
    // console.log("onZoomEnd start");
    if (!mapRef.current || !geometryFetcher) {
      // console.log("onZoomEnd premature end");
      return;
    }

    setCurrentZoom(mapRef.current.leafletElement.getZoom());

    // console.log("onZoomEnd end");
  }


  // Hook to track mounted state to help us
  // prevent state updates if unmounted
  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    }
  }, [])


  // Hook for initial setup of the map view
  useEffect(() => {
    if (!mapRef.current) return;

    // console.log("Running initialization effect");

    const map = mapRef.current.leafletElement;
    const mapCenter = center ?? marker?.position ?? defaultCenter;
    const mapZoom = zoom || defaultZoom;
    map.setView(mapCenter, mapZoom);

    const interval = setInterval(() => map.invalidateSize(), 100);
    return () => clearInterval(interval);
  }, [center, marker, zoom]);


  // Hook for polygon layer updates if geometryFetcher is provided
  useEffect(() => {
    if (!mapRef.current || !geometryFetcher || !bounds._southWest) return;

    // console.log("Running geometry fetch effect");

    (async function callFetch() {
      // console.log("callFetch called");

      const geometries = await geometryFetcher(bounds);
      // console.log(`geometries fetched: ${JSON.stringify(geometries)}`);

      if (isMountedRef.current) {
        setPolygons(geometries || []);
        // console.log("State updated with fetched geometries");
      }
    })();
  }, [bounds]);


  return (
    <LeafletMap
      ref={mapRef}
      center={center}
      zoom={currentZoom}
      className={classes.leafletContainer}
      onMoveend={onMoveEnd}
      onZoomend={onZoomEnd}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> přispěvatelé'
      />
      {marker &&
        <Marker position={marker.position}>
          {marker.label && <Popup>{marker.label}</Popup>}
        </Marker>}
      {geometry && <Polygon positions={geometry.geometry} color={GeometryColor(geometry.active)}/>}
      {geometryFetcher && polygons.map(geometry =>
        <Fragment key={geometry.id}>
          <Polygon positions={geometry.polygons} color={GeometryColor(geometry.active)}/>
          {geometry.marker && currentZoom >= minZoomWithMarker &&
            <Marker position={geometry.marker.position}>
              {geometry.marker.label &&
                <Popup autoPan={false}>
                  <>
                    {geometry.marker.label}
                    <br />
                    <Link href={`#/institutions/${geometry.id}`}>Detail</Link>
                  </>
                </Popup>
              }
            </Marker>
          }
        </Fragment>
      )}
    </LeafletMap>
  );
};

MapComponent.propTypes = {
  classes: PropTypes.object,
  center: PropTypes.array,
  zoom: PropTypes.number,
  marker: PropTypes.object,
  geometry: PropTypes.object,
  geometryFetcher: PropTypes.func,
};

const Map = props => {
  const { styles, label } = props;

  const classes = getStyles(styles)();

  return (label ?
    <Labeled label={label} className={classes.leafletContainer}>
      <MapComponent classes={classes} {...props} />
    </Labeled> :
    <MapComponent classes={classes} {...props} />
  );
}

Map.propTypes = {
  styles: PropTypes.object,
  label: PropTypes.string
};

export default Map;