import React from "react";
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Create,
  Edit,
  List,
  NumberField,
  PasswordInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import { roles } from "./authprovider/authprovider";
import {
  loginNameInputValidator,
  requiredPasswordInputValidator,
  requiredConfirmedPasswordInputValidator,
  requiredInputValidator,
  emailInputValidator,
  phoneNumberInputValidator,
  passwordInputValidator,
  confirmedPasswordInputValidator,
} from "./validators";
import { dateTimeLocales, maxPerPage } from "./constants";
import { genderChoices } from "./common";
import CustomPagination from "./components/pagination";

const UserCreateTitle = () => <span>Nový uživatel</span>;
const UserEditTitle = ({ record }) => {
  let title = "Detail uživatele";
  if (record != null) {
    title += ` ${record.firstName} ${record.lastName}`;
  }

  return <span>{title}</span>;
};

const UserFilter = (permissions) => [
  <TextInput source="email" />,
  (permissions === roles.SystemAdminRoleID ?
    <ReferenceInput
      source="institutionId"
      reference="institutions"
      perPage={maxPerPage}
      sort={{ field: "name", order: "ASC" }}
    >
      <AutocompleteInput source="name" />
    </ReferenceInput> : null
  ),
  <TextInput source="firstName" />,
  <TextInput source="loginName" />,
  <TextInput source="lastName" />,
  <ReferenceInput source="roleId" reference="roles">,
    <SelectInput source="name" />
  </ReferenceInput>
].filter(filter => filter !== null);

export const UserList = ({ permissions, ...props }) => (
  <List filters={UserFilter(permissions)} pagination={<CustomPagination/>} {...props}>
    <Datagrid
      rowClick={permissions <= roles.InstitutionAdminRoleID ? "edit" : "show"}
    >
      <TextField source="id" />
      <TextField source="loginName" />
      <TextField source="lastName" />
      <TextField source="firstName" />
      <TextField source="email" />
      <ReferenceField source="institutionId" reference="institutions">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="roleId" reference="roles" link={false}>
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export const UserCreate = ({ permissions, ...props }) => (
  <Create title={<UserCreateTitle />} {...props}>
    <SimpleForm redirect="list">
      <TextInput source="loginName" validate={loginNameInputValidator} />
      <PasswordInput
        source="password"
        label="Heslo"
        validate={requiredPasswordInputValidator}
      />
      <PasswordInput
        source="passwordConfirm"
        label="Potvrzení hesla"
        validate={requiredConfirmedPasswordInputValidator}
      />
      <TextInput source="firstName" validate={requiredInputValidator} />
      <TextInput source="lastName" validate={requiredInputValidator} />
      <SelectInput
        source="gender"
        choices={genderChoices}
        validate={requiredInputValidator}
      />
      <ReferenceInput
        source="roleId"
        reference="roles"
        validate={requiredInputValidator}
        sort={{ field: "id", order: "ASC" }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="institutionId"
        reference="institutions"
        perPage={maxPerPage}
        sort={{ field: "name", order: "ASC" }}
        validate={requiredInputValidator}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput source="email" validate={emailInputValidator} />
      <TextInput source="phoneNumber" validate={phoneNumberInputValidator} />
    </SimpleForm>
  </Create>
);

export const UserEdit = ({ permissions, ...props }) => (
  <Edit title={<UserEditTitle />} {...props}>
    <SimpleForm redirect="list">
      <NumberField source="id" />
      <TextField source="loginName" />
      <PasswordInput
        source="password"
        label="Nové heslo"
        validate={passwordInputValidator}
      />
      <PasswordInput
        source="passwordConfirm"
        label="Potvrzení nového hesla"
        validate={confirmedPasswordInputValidator}
      />
      <TextInput source="firstName" validate={requiredInputValidator} />
      <TextInput source="lastName" validate={requiredInputValidator} />
      <SelectInput
        source="gender"
        choices={genderChoices}
        validate={requiredInputValidator}
      />
      <ReferenceInput
        source="roleId"
        reference="roles"
        validate={requiredInputValidator}
        sort={{ field: "id", order: "ASC" }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      {permissions === roles.SystemAdminRoleID ? (
        <ReferenceInput
          source="institutionId"
          reference="institutions"
          perPage={maxPerPage}
          sort={{ field: "name", order: "ASC" }}
          validate={requiredInputValidator}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      ) : (
        <ReferenceField source="institutionId" reference="institutions">
          <TextField source="name" />
        </ReferenceField>
      )}

      <TextInput source="email" validate={emailInputValidator} />
      <TextInput source="phoneNumber" validate={phoneNumberInputValidator} />
      <DateField source="created" locales={dateTimeLocales} />
    </SimpleForm>
  </Edit>
);
