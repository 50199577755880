import React from "react";
import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  EditController,
  EditView,
  FileField,
  FileInput,
  FormTab,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ShowController,
  ShowView,
  Tab,
  TabbedForm,
  TabbedShowLayout,
  TextField,
  TextInput,
  useTranslate,
} from "react-admin";
import {roles} from "./authprovider/authprovider";
import {requiredInputValidator} from "./validators";
import {dateTimeLocales, maxPerPage} from "./constants";
import CustomPagination from "./components/pagination";
import Map from "./components/map";

const AreaCreateTitle = () => <span>Nová oblast</span>;
const AreaEditTitle = ({record}) => {
  let title = "Detail oblasti";
  if (record != null) {
    title += ` ${record.fullName}`;
  }
  return <span>{title}</span>;
};

const AreaFilter = () => [
  <BooleanInput source="isPolygonSet"/>,
  <TextInput source="fullName"/>,
  <ReferenceInput
    source="regionId"
    reference="regions"
    perPage={maxPerPage}
    sort={{field: "name", order: "ASC"}}
  >
    <SelectInput source="name"/>
  </ReferenceInput>,
  <TextInput source="shortName"/>
];

export const AreaList = ({permissions, ...props}) => (
  <List filters={AreaFilter()} pagination={<CustomPagination/>} {...props}>
    <Datagrid
      rowClick={permissions === roles.SystemAdminRoleID ? "edit" : "show"}
    >
      <TextField source="id"/>
      <TextField source="shortName"/>
      <ReferenceField source="regionId" reference="regions">
        <TextField source="name"/>
      </ReferenceField>
    </Datagrid>
  </List>
);

export const AreaCreate = (props) => {
  const translate = useTranslate();
  return (
    <Create title={<AreaCreateTitle/>} {...props}>
      <TabbedForm redirect="list">
        <FormTab label={translate("tabLabels.general")}>
          <TextInput source="shortName" validate={requiredInputValidator}/>
          <TextInput source="fullName" validate={requiredInputValidator}/>
          <ReferenceInput
            source="regionId"
            reference="regions"
            validate={requiredInputValidator}
          >
            <AutocompleteInput optiontext="name"/>
          </ReferenceInput>
        </FormTab>
        <FormTab label={translate("resources.areas.tabLabels.polygon")}>
          <FileInput source="polygonData">
            <FileField source="src" title="title"/>
          </FileInput>
        </FormTab>
      </TabbedForm>
    </Create>
  )
};

export const AreaEdit = (props) => {
  const translate = useTranslate();
  return (
    <EditController {...props}>
      {(controllerProps) => (
        <EditView title={<AreaEditTitle/>} {...props} {...controllerProps}>
          <TabbedForm redirect="list">
            <FormTab label={translate("tabLabels.general")}>
              <NumberField source="id"/>
              <TextInput source="shortName" validate={requiredInputValidator}/>
              <TextInput source="fullName" validate={requiredInputValidator}/>
              <ReferenceInput
                source="regionId"
                reference="regions"
                validate={requiredInputValidator}
              >
                <AutocompleteInput optiontext="name"/>
              </ReferenceInput>
              <DateField source="created" locales={dateTimeLocales}/>
              {controllerProps.record && controllerProps.record.updated && (
                <DateField source="updated" locales={dateTimeLocales}/>
              )}
            </FormTab>
            <FormTab label={translate("resources.areas.tabLabels.polygon")}>
              <BooleanField
                source="isPolygonSet"
                valueLabelTrue="Ano"
                valueLabelFalse="Ne"
              />
              <FileInput source="polygonData">
                <FileField source="src" title="title"/>
              </FileInput>
              {controllerProps.record && controllerProps.record.geometry &&
                <Map
                  label={translate("resources.areas.fields.geometry")}
                  center={controllerProps.record.geometry.center}
                  geometry={{geometry: controllerProps.record.geometry.polygons}}
                  zoom={13}
                />
              }
            </FormTab>
          </TabbedForm>
        </EditView>
      )}
    </EditController>
  )
};

export const AreaShow = (props) => {
  const translate = useTranslate();
  return (
    <ShowController {...props}>
      {(controllerProps) => (
        <ShowView title={<AreaEditTitle/>} {...props} {...controllerProps}>
          <TabbedShowLayout>
            <Tab label={translate("tabLabels.general")}>
              <NumberField source="id"/>
              <TextField source="shortName"/>
              <TextField source="fullName"/>
              <ReferenceField source="regionId" reference="regions">
                <TextField source="name"/>
              </ReferenceField>
              <DateField source="created" locales={dateTimeLocales}/>
              {controllerProps.record && controllerProps.record.updated && (
                <DateField source="updated" locales={dateTimeLocales}/>
              )}
            </Tab>
            <Tab label={translate("resources.areas.tabLabels.polygon")}>
              <BooleanField
                source="isPolygonSet"
                valueLabelTrue="Ano"
                valueLabelFalse="Ne"
              />
              {controllerProps.record && controllerProps.record.geometry &&
                <Map
                  label={translate("resources.areas.fields.geometry")}
                  center={controllerProps.record.geometry.center}
                  geometry={{geometry: controllerProps.record.geometry.polygons}}
                  zoom={13}/>
              }
            </Tab>
          </TabbedShowLayout>
        </ShowView>
      )}
    </ShowController>
  )
};
