import React from "react";
import {List, useListContext, useDataProvider, useTranslate} from "react-admin";
import Map from "./components/map";
import {Typography} from "@material-ui/core";

// Function for fetching geometries from API
// when the bounds of the map viewport change
const GeometryFetcher = dataProvider => async bounds => {
  // console.log(`Bounds for call: ${JSON.stringify(bounds)}`);
  // bounds: {"_southWest":{"lat":50.08622555591017,"lng":14.232959747314453},"_northEast":{"lat":50.17425960242971,"lng":14.513797760009767}}
  const listAttributes = (bounds && bounds._southWest) ? {
    filter: {
      minLon: bounds._southWest.lng,
      minLat: bounds._southWest.lat,
      maxLon: bounds._northEast.lng,
      maxLat: bounds._northEast.lat
    }
  } : {};

  return dataProvider
    .getList('hggeometries', listAttributes)
    .then(resp => resp.data.map(item => {
      const result = {
        id: item.id,
        active: item.active,
        polygons: item.geometry.polygons,
      };

      if (item.geometry.center) {
        result.marker = {
          label: item.name,
          position: item.geometry.center
        };
      }

      return result;
    }))
    .catch(e => {
      console.log(e);
      return [];
    });
}

const HuntingGroundMap = () => {
  const translate = useTranslate();
  const warningText = translate("resources.hggeometries.simplificationWarning");
  const dataProvider = useDataProvider();
  const styles = { height: "800px", marginBottom: "0" };

  return (
    <>
      <Map
        styles={styles}
        geometryFetcher={GeometryFetcher(dataProvider)}
        zoom={13}
      />
      <Typography variant="h6">
        {warningText}
      </Typography>
    </>);
}

export const HuntingGroundGeometriesList = props => {
  const map = <HuntingGroundMap />;
  return (
    <List
      resource="hggeometries"
      basePath="/hggeometries"
      actions={false}
      pagination={null}
      empty={map}
      {...props}
    >
      {map}
    </List>
  );
}