import React from "react";
import { AppBar, Toolbar, Typography } from "@material-ui/core";

const Header = ({ title, ...props }) => (
  <AppBar position="static" {...props}>
    <Toolbar>
      <Typography variant="h5">{title}</Typography>
    </Toolbar>
  </AppBar>
);

export default Header;
