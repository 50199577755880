import React from "react";
import {
  ArrayInput,
  AutocompleteInput,
  Create,
  ChipField,
  Datagrid,
  DateField,
  EditController,
  EditView,
  EmailField,
  FormTab,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SelectArrayInput,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
  useTranslate
} from "react-admin";
import DateFnsUtils from "@date-io/date-fns";
import csLocale from "date-fns/locale/cs";
import {TimeInput} from "react-admin-date-inputs";
import {
  requiredInputValidator,
  emailInputValidator,
  phoneNumberInputValidator,
} from "./validators";
import {maxPerPage} from "./constants";
import {roles} from "./authprovider/authprovider";
import {dateTimeLocales} from "./constants";
import {genderChoices} from "./common";

// Definitions of weekdays. Sunday uses id 0
// to make it consistent with how Golang defines
// weekdays
const days = [
  {id: 1, name: "Pondělí"},
  {id: 2, name: "Úterý"},
  {id: 3, name: "Středa"},
  {id: 4, name: "Čtvrtek"},
  {id: 5, name: "Pátek"},
  {id: 6, name: "Sobota"},
  {id: 0, name: "Neděle"},
];

const ContactPersonCreateTitle = () => <span>Nová kontaktní osoba</span>;
const ContactPersonEditTitle = ({record}) => {
  let title = "Detail kontaktní osoby";
  if (record != null) {
    title += ` ${record.firstName} ${record.lastName}`;
  }
  return <span>{title}</span>;
};

const ContactPersonFilter = (permissions) => [
  <TextInput source="email"/>,
  permissions === roles.SystemAdminRoleID ?
    <ReferenceInput
      source="institutionId"
      reference="institutions"
      perPage={maxPerPage}
      sort={{field: "name", order: "ASC"}}
    >
      <AutocompleteInput source="name"/>
    </ReferenceInput> : null,
  <TextInput source="firstName"/>,
  <TextInput source="lastName"/>
].filter(filter => filter !== null);

export const ContactPersonFullNameReversed = record =>
  `${record.lastName}, ${record.firstName}`;

export const ContactPersonList = ({permissions, ...rest}) => (
  <List filters={ContactPersonFilter(permissions)} {...rest}>
    <Datagrid rowClick="edit">
      <NumberField source="id"/>
      <TextField source="lastName"/>
      <TextField source="firstName"/>
      <EmailField source="email"/>
      <ReferenceField source="institutionId" reference="institutions">
        <TextField source="name"/>
      </ReferenceField>
    </Datagrid>
  </List>
);

export const ContactPersonEdit = (props) => {
  const translate = useTranslate();
  return (
    <EditController {...props}>
      {(controllerProps) => (
        <EditView
          title={<ContactPersonEditTitle/>}
          {...props}
          {...controllerProps}
        >
          <TabbedForm redirect="list">
            <FormTab label={translate("tabLabels.general")}>
              <NumberField source="id"/>
              <TextInput source="title"/>
              <TextInput source="firstName" validate={requiredInputValidator}/>
              <TextInput source="lastName" validate={requiredInputValidator}/>
              <SelectInput
                source="gender"
                choices={genderChoices}
                validate={requiredInputValidator}
              />
              <ReferenceInput
                source="institutionId"
                reference="institutions"
                perPage={maxPerPage}
                sort={{field: "name", order: "ASC"}}
                validate={requiredInputValidator}
              >
                <AutocompleteInput name="name"/>
              </ReferenceInput>
              <ReferenceInput
                source="preferredCommunicationMethodId"
                reference="communicationmethods"
                validate={requiredInputValidator}
                sort={{field: "id", order: "ASC"}}
              >
                <SelectInput optionText="name"/>
              </ReferenceInput>
              <TextInput
                source="phoneNumber"
                validate={phoneNumberInputValidator}
              />
              <TextInput source="email" validate={emailInputValidator}/>
              <DateField source="created" locales={dateTimeLocales}/>
              {controllerProps.record && controllerProps.record.updated && (
                <DateField source="updated" locales={dateTimeLocales}/>
              )}
            </FormTab>
            <FormTab label={translate("resources.contactpersons.fields.workingHours")}>
              <ArrayInput source="workingHours" key="id" fullWidth>
                <SimpleFormIterator>
                  <TimeInput
                    source="start"
                    label={translate("resources.contactpersons.fields.start")}
                    options={{ampm: false, format: "HH:mm"}}
                    providerOptions={{utils: DateFnsUtils, locale: csLocale}}
                    validate={requiredInputValidator}
                  />
                  <TimeInput
                    source="end"
                    label={translate("resources.contactpersons.fields.end")}
                    options={{ampm: false, format: "HH:mm"}}
                    providerOptions={{utils: DateFnsUtils, locale: csLocale}}
                    validate={requiredInputValidator}
                  />
                  <SelectArrayInput
                    source="days"
                    label={translate("resources.contactpersons.fields.days")}
                    choices={days}
                    validate={requiredInputValidator}
                  >
                    <ChipField source="name"/>
                  </SelectArrayInput>
                </SimpleFormIterator>
              </ArrayInput>
            </FormTab>
          </TabbedForm>
        </EditView>
      )}
    </EditController>
  )
};

export const ContactPersonCreate = (props) => {
  const translate = useTranslate();
  return (
    <Create title={<ContactPersonCreateTitle/>} {...props}>
      <TabbedForm redirect="list">
        <FormTab label={translate("tabLabels.general")}>
          <TextInput source="title"/>
          <TextInput source="firstName" validate={requiredInputValidator}/>
          <TextInput source="lastName" validate={requiredInputValidator}/>
          <SelectInput
            source="gender"
            choices={genderChoices}
            validate={requiredInputValidator}
          />
          <ReferenceInput
            source="institutionId"
            reference="institutions"
            perPage={maxPerPage}
            sort={{field: "name", order: "ASC"}}
            validate={requiredInputValidator}
          >
            <AutocompleteInput name="name"/>
          </ReferenceInput>
          <ReferenceInput
            source="preferredCommunicationMethodId"
            reference="communicationmethods"
            validate={requiredInputValidator}
            sort={{field: "id", order: "ASC"}}
          >
            <SelectInput optionText="name"/>
          </ReferenceInput>
          <TextInput source="email" validate={emailInputValidator}/>
          <TextInput source="phoneNumber" validate={phoneNumberInputValidator}/>
        </FormTab>
        <FormTab label={translate("resources.contactpersons.fields.workingHours")}>
          <ArrayInput source="workingHours" key="id" fullWidth>
            <SimpleFormIterator>
              <TimeInput
                source="start"
                label={translate("resources.contactpersons.fields.start")}
                options={{ampm: false, format: "HH:mm"}}
                providerOptions={{utils: DateFnsUtils, locale: csLocale}}
                validate={requiredInputValidator}
              />
              <TimeInput
                source="end"
                label={translate("resources.contactpersons.fields.end")}
                options={{ampm: false, format: "HH:mm"}}
                providerOptions={{utils: DateFnsUtils, locale: csLocale}}
                validate={requiredInputValidator}
              />
              <SelectArrayInput
                source="days"
                label={translate("resources.contactpersons.fields.days")}
                choices={days}
                validate={requiredInputValidator}
              >
                <ChipField source="name"/>
              </SelectArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  )
};
