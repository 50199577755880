import React from "react";
import {
  Edit,
  NumberField,
  PasswordInput,
  ReferenceField,
  SimpleForm,
  TextField,
  TextInput,
  required,
  useTranslate
} from "react-admin";
import {
  SystemAdministratorUserID,
  currentUserID,
} from "./authprovider/authprovider";
import {
  emailInputValidator,
  requiredInputValidator,
  passwordInputValidator,
  phoneNumberInputValidator,
} from "./validators";
import ExtendedToolbar from "./components/extendedtoolbar";

const currentPasswordInputValidator = [
  required("Současné heslo je vyžadované pro validaci"),
];

const AccountEditTitle = () => {
  const translate = useTranslate();
  return translate("resources.account.name");
}

const NoteText = () => {
  const translate = useTranslate();
  if (currentUserID() === SystemAdministratorUserID) {
    return translate("resources.account.notes.systemAdminAccount");
  }

  return translate("resources.account.notes.nonSystemAdminAccount");
}

// staticContext is superfluous, so it is captured here
// so as to not get passed further down
const AccountEdit = ({staticContext, ...rest}) => (
  <Edit
    id={localStorage.getItem("id")}
    resource="account"
    basePath="/account"
    title={<AccountEditTitle/>}
    redirect={false}
    {...rest}
  >
    <SimpleForm toolbar={<ExtendedToolbar noteText={<NoteText />} showSave/>}>
      <NumberField source="id"/>
      <TextField source="loginName"/>
      {currentUserID() !== SystemAdministratorUserID ? (
        <ReferenceField source="institutionId" reference="institutions">
          <TextField source="name"/>
        </ReferenceField>
      ) : null}
      <ReferenceField source="roleId" reference="roles" link={false}>
        <TextField source="name"/>
      </ReferenceField>
      <TextInput source="firstName" validate={requiredInputValidator}/>
      <TextInput source="lastName" validate={requiredInputValidator}/>
      <PasswordInput source="password" validate={passwordInputValidator}/>
      <PasswordInput
        source="passwordConfirm"
        validate={passwordInputValidator}
      />
      <TextInput source="email" validate={emailInputValidator}/>
      <TextInput source="phoneNumber" validate={phoneNumberInputValidator}/>
      <PasswordInput
        source="currentPassword"
        validate={currentPasswordInputValidator}
      />
    </SimpleForm>
  </Edit>
);

export default AccountEdit;
