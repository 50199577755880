import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItemLink, getResources, usePermissions, useTranslate } from "react-admin";
import DefaultIcon from "@material-ui/icons/ViewList";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CorrelationIcon from "../icons/correlation";
import {roles} from "../authprovider/authprovider";

const useStyles = makeStyles({
  logoBox: {
    display: "flex",
    position: "fixed",
    bottom: "4px",
    left: "8px",
  },
});

const Menu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  const translate = useTranslate();
  const {permissions} = usePermissions();
  const classes = useStyles();
  return (
    <div>
      <div>
        {resources.map((resource) => {
          return (resource.hasList && (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={translate(`resources.${resource.name}.name`)}
              leftIcon={resource.icon ? <resource.icon/> : <DefaultIcon/>}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />)
          );
        })}
        {permissions === roles.SystemAdminRoleID &&
          <MenuItemLink
            to="/correlationsettings"
            primaryText={translate("resources.correlationsettings.name")}
            leftIcon={<CorrelationIcon/>}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        }
        {isXSmall && logout}
      </div>

      <Box className={classes.logoBox}>
        <Grid container direction="row" spacing={1}>
          <Grid item>
            <img src="logos/eu.png" alt="Logo Evropské unie" />
          </Grid>
          <Grid item>
            <img src="logos/praha.png" alt="Logo Hlavního města Prahy" />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Menu;
