import { createTheme } from "@material-ui/core";
import { csCZ } from "@material-ui/core/locale";

const primaryColor = "#008577";
const secondaryColor = "#008577";

const theme = createTheme(
  {
    palette: {
      primary: { main: primaryColor },
      secondary: { main: secondaryColor },
    },
    overrides: {
      RaSidebar: {    // This should be a temporary fix of the issue discussed here: https://github.com/marmelab/react-admin/issues/6630
        fixed: {
          position: 'inherit'
        }
      }
    }
  },
  csCZ
);

export default theme;
