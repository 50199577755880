import { CREATE, UPDATE } from "react-admin";

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 */
const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const geometryUploadHandler = (requestHandler) => (type, resource, params) => {
  if (
    (type === CREATE || type === UPDATE) &&
    (resource === "areas" || resource === "institutions")
  ) {
    if (
      params.data.polygonData &&
      params.data.polygonData.rawFile instanceof File
    ) {
      return Promise.resolve(convertFileToBase64(params.data.polygonData)).then(
        (fileData) => {
          fileData = fileData.split(",")[1]; // Remove the data URL prefix so that only the base64 encoded data is retained
          return requestHandler(type, resource, {
            ...params,
            data: {
              ...params.data,
              polygonData: fileData,
            },
          });
        }
      );
    }
  }

  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params);
};

export default geometryUploadHandler;
