import { apiURL } from "../constants";
import decodeJwt from "jwt-decode";

const authProvider = {
  login: async ({ username, password }) => {
    const url = `${apiURL}/login`;
    const request = new Request(url, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ token }) => {
        localStorage.setItem("token", token);

        // Parse the role id from the token for authorization checks
        const decodedToken = decodeJwt(token);
        localStorage.setItem("id", decodedToken.sub);
        localStorage.setItem("role", decodedToken.role);
        localStorage.setItem("name", decodedToken.name);
      });
  },
  logout: async () => {
    localStorage.removeItem("id");
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("name");
    return Promise.resolve();
  },
  checkAuth: async () =>
    localStorage.getItem("token") ? Promise.resolve() : Promise.reject(),
  checkError: async (error) => {
    const status = error.status;
    if (status === 401) {
      localStorage.removeItem("id");
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      localStorage.removeItem("name");
      return Promise.reject();
    }

    Promise.resolve();
  },
  getPermissions: async () => {
    let role = parseInt(localStorage.getItem("role"), 10);
    return Promise.resolve(role);
  },
};

export const currentUserID = () => parseInt(localStorage.getItem("id"), 10);
export const SystemAdministratorUserID = 1;
export const roles = {
  SystemAdminRoleID: 1,
  InstitutionAdminRoleID: 2,
  InstitutionReportAdminRoleID: 3,
};
export default authProvider;
