import React from "react";
import { useForm } from "react-final-form";
import { AutocompleteInput, ReferenceInput } from "react-admin";
import { requiredInputValidator } from "../validators";
import { maxPerPage } from "../constants";

const LocationInput = ({ formData, ...rest }) => {
  const form = useForm();

  return (
    <>
      <ReferenceInput
        source="regionId"
        reference="regions"
        perPage={maxPerPage}
        sort={{ field: "name", order: "ASC" }}
        validate={requiredInputValidator}
        onChange={(value) => form.change("areaId", null)}
        {...rest}
      >
        <AutocompleteInput source="name" />
      </ReferenceInput>
      <br />
      <ReferenceInput
        source="areaId"
        reference="areas"
        perPage={maxPerPage}
        sort={{ field: "fullName", order: "ASC" }}
        filter={{ regionId: formData.regionId }}
        validate={requiredInputValidator}
        {...rest}
      >
        <AutocompleteInput optionText="fullName" />
      </ReferenceInput>
    </>
  );
};

export default LocationInput;
