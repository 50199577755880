import React from "react";
import {
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
  useTranslate,
} from "react-admin";

const ReportStatusShowTitle = ({ record }) => {
  const translate = useTranslate();
  let title = translate("resources.reportstatuses.single");
  if (record != null) {
    title += ` ${record.name}`;
  }
  return <span>{title}</span>;
};

export const ReportStatusShow = (props) => (
  <Show title={<ReportStatusShowTitle />} {...props}>
    <SimpleShowLayout>
      <NumberField source="id" />
      <TextField source="name" />
    </SimpleShowLayout>
  </Show>
);
