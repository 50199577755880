import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import moment from "moment";
import {
  ArrayField,
  AutocompleteInput,
  BooleanInput,
  Datagrid,
  DateField,
  EditController,
  EditView,
  FormTab,
  FunctionField,
  ImageField,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  useTranslate,
} from "react-admin";
import DateFnsUtils from "@date-io/date-fns";
import csLocale from "date-fns/locale/cs";
import {DateTimeInput} from "react-admin-date-inputs";
import {dateTimeLocales, maxPerPage} from "./constants";
import {
  requiredInputValidator,
  longitudeInputValidator,
  latitudeInputValidator,
  gpsAccuracyInputValidator,
} from "./validators";
import {roles} from "./authprovider/authprovider";
import {ContactPersonFullNameReversed} from "./contactpersons";
import CustomPagination from "./components/pagination";
import Map from "./components/map";

const dateTimeFilterFormatString = "d. MMMM yyyy, HH:mm";

const useStyles = makeStyles({
  photo: {
    // Photos come from the apps in fullHD resolution tops,
    // this cuts that size to two thirds in each dimension
    "& img": {
      maxWidth: 1280,
      maxHeight: 720,
    },
  }
});

const ReportFilter = (permissions) => {
  const translate = useTranslate();
  return [
    <SearchInput
      source="animalName"
      placeholder={translate("resources.reports.searchAnimal")}
      alwaysOn
    />,
    <DateTimeInput
      source="updatedFrom"
      label={translate("resources.reports.fields.updatedFrom")}
      options={{ampm: false, format: dateTimeFilterFormatString}}
      providerOptions={{utils: DateFnsUtils, locale: csLocale}}
    />,
    <DateTimeInput
      source="updatedTo"
      label={translate("resources.reports.fields.updatedTo")}
      options={{ampm: false, format: dateTimeFilterFormatString}}
      providerOptions={{utils: DateFnsUtils, locale: csLocale}}
    />,
    permissions === roles.SystemAdminRoleID ?
      <ReferenceInput
        source="areaId"
        reference="areas"
        perPage={maxPerPage}
        sort={{field: "fullName", order: "ASC"}}
      >
        <AutocompleteInput optionText="fullName"/>
      </ReferenceInput> : null,
    <DateTimeInput
      source="receivedFrom"
      label={translate("resources.reports.fields.receivedFrom")}
      options={{ampm: false, format: dateTimeFilterFormatString}}
      providerOptions={{utils: DateFnsUtils, locale: csLocale}}
    />,
    <DateTimeInput
      source="receivedTo"
      label={translate("resources.reports.fields.receivedTo")}
      options={{ampm: false, format: dateTimeFilterFormatString}}
      providerOptions={{utils: DateFnsUtils, locale: csLocale}}
    />,
    <ReferenceInput
      source="reportStatusId"
      reference="reportstatuses"
      sort={{field: "id", order: "ASC"}}
    >
      <SelectInput source="name"/>
    </ReferenceInput>,
    <DateTimeInput
      source="createdFrom"
      label={translate("resources.reports.fields.createdFrom")}
      options={{ampm: false, format: dateTimeFilterFormatString}}
      providerOptions={{utils: DateFnsUtils, locale: csLocale}}
    />,
    <DateTimeInput
      source="createdTo"
      label={translate("resources.reports.fields.createdTo")}
      options={{ampm: false, format: dateTimeFilterFormatString}}
      providerOptions={{utils: DateFnsUtils, locale: csLocale}}
    />,
    <ReferenceInput
      source="healthStateId"
      reference="healthstates"
      sort={{field: "id", order: "ASC"}}
    >
      <SelectInput source="name"/>
    </ReferenceInput>
  ].filter(filter => filter !== null);
};

export const ReportList = ({permissions, ...props}) => (
  <List filters={ReportFilter(permissions)} pagination={<CustomPagination/>} {...props}>
    <Datagrid rowClick="edit">
      <NumberField source="id"/>
      <TextField source="animalName"/>
      <ReferenceField
        source="healthStateId"
        reference="healthstates"
        link={false}
      >
        <TextField source="name"/>
      </ReferenceField>
      <ReferenceField
        source="areaId"
        reference="areas"
        link={permissions === roles.SystemAdminRoleID ? "edit" : false}
      >
        <TextField source="fullName"/>
      </ReferenceField>
      <ReferenceField
        source="reportStatusId"
        reference="reportstatuses"
        link={false}
      >
        <TextField source="name"/>
      </ReferenceField>
      <DateField source="created" locales={dateTimeLocales} showTime/>
      <DateField source="received" locales={dateTimeLocales} showTime/>
      <DateField source="updated" locales={dateTimeLocales} showTime/>
    </Datagrid>
  </List>
);

const ReportEditTitle = ({record}) => {
  const translate = useTranslate();
  let title = translate("resources.reports.single");
  if (record != null) {
    let created = moment(record.created);
    title = record.animalName + " ze dne " + created.format("DD. MM. YYYY");
  }
  return <span>{title}</span>;
};

export const ReportEdit = ({permissions, ...rest}) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <EditController {...rest}>
      {(controllerProps) => (
        <EditView title={<ReportEditTitle/>} {...rest} {...controllerProps}>
          <TabbedForm redirect="list">
            <FormTab label={translate("tabLabels.general")}>
              <NumberField source="id"/>
              <TextField source="source"/>
              <ReferenceInput
                source="reportStatusId"
                reference="reportstatuses"
                sort={{field: "id", order: "ASC"}}
                validate={requiredInputValidator}
              >
                <SelectInput optionText="name"/>
              </ReferenceInput>
              <TextInput source="animalName" validate={requiredInputValidator}/>
              <ReferenceInput
                source="healthStateId"
                reference="healthstates"
                sort={{field: "id", order: "ASC"}}
                validate={requiredInputValidator}
              >
                <SelectInput optionText="name"/>
              </ReferenceInput>
              <TextInput multiline source="message" fullWidth/>
              <BooleanInput source="isGame"/>
              <DateField source="created" locales={dateTimeLocales} showTime/>
              {controllerProps.record && controllerProps.record.updated && (
                <DateField source="updated" locales={dateTimeLocales} showTime/>
              )}
            </FormTab>
            <FormTab label={translate("resources.reports.tabLabels.location")}>
              <TextInput source="longitude" validate={longitudeInputValidator}/>
              <TextInput source="latitude" validate={latitudeInputValidator}/>
              <TextInput source="accuracy" validate={gpsAccuracyInputValidator}/>
              <ReferenceInput
                source="areaId"
                reference="areas"
                perPage={maxPerPage}
                sort={{field: "fullName", order: "ASC"}}
                validate={requiredInputValidator}
              >
                <AutocompleteInput optionText="fullName"/>
              </ReferenceInput>
              {controllerProps.record && controllerProps.record.longitude !== 0.0 &&
                <Map
                  label={translate("resources.reports.fields.location")}
                  marker={
                    {
                      position: [controllerProps.record.latitude, controllerProps.record.longitude],
                      label: translate("resources.reports.popupLabels.reportLocation")
                    }
                  }
                />
              }
            </FormTab>
            <FormTab label={translate("resources.reports.tabLabels.photo")}>
              {controllerProps.record && (controllerProps.record.photoUrl
                  ? (<ImageField
                    addLabel className={classes.photo} source="photoUrl"
                    label={translate("resources.reports.fields.photoUrl")}/>)
                  : (<Typography>{translate("resources.reports.staticText.noPhoto")}</Typography>)
              )}
            </FormTab>
            <FormTab label={translate("resources.reports.tabLabels.notifications")}>
              <ArrayField source="notifications" fieldKey="id" key="id" fullWidth>
                <Datagrid>
                  <ReferenceField
                    label={translate("resources.contactpersons.single")}
                    source="contactPersonId"
                    reference="contactpersons"
                    link={permissions === roles.SystemAdminRoleID ? "edit" : false}
                    emptyText="test text"
                  >
                    <FunctionField
                      source="lastName"
                      render={ContactPersonFullNameReversed}
                    />
                  </ReferenceField>
                  <ReferenceField
                    label={translate("resources.institutions.single")}
                    source="institutionId"
                    reference="institutions"
                    link={permissions === roles.SystemAdminRoleID ? "edit" : false}
                  >
                    <TextField source="name" />
                  </ReferenceField>
                  <ReferenceField
                    label="Stav"
                    source="stateId"
                    reference="notificationstates"
                    link={false}
                  >
                    <TextField source="name"/>
                  </ReferenceField>
                  <DateField
                    label="Vytvořeno"
                    source="created"
                    locales={dateTimeLocales}
                    showTime
                  />
                </Datagrid>
              </ArrayField>
            </FormTab>
          </TabbedForm>
        </EditView>
      )}
    </EditController>
  );
};
