import React from "react";
import {
  Box,
  Grid,
  Link,
  ThemeProvider,
  Typography,
  makeStyles,
  Container,
} from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import Header from "./header";
import theme from "../theme/theme";

const useStyles = makeStyles({
  topLevelContainerBox: {
    position: "absolute",
    left: "0",
    top: "0",
    bottom: "0",
    right: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  messageContainer: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "66%",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "33%",
    },
    backgroundColor: "paper",
    border: "solid",
    borderColor: grey[300],
    borderRadius: "6px",
    borderWidth: "1px",
    padding: "15px",
    verticalAlign: "center",
  },
});

const PasswordChanged = () => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Grid container direction="column">
        <Header title="Hotovo!" />
      </Grid>
      <Box className={classes.topLevelContainerBox}>
        <Container className={classes.messageContainer}>
          <Typography variant="h5">
            Vaše heslo bylo úspěšně změneno. Pro přihlášení pokračujte, prosím,{" "}
            <Link href="/">zde</Link>.
          </Typography>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default PasswordChanged;
