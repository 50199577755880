const Settings = {
  total: 'total',
  headers: {
    Accept: 'application/vnd.api+json; charset=utf-8',
    'Content-Type': 'application/vnd.api+json; charset=utf-8',
  },
  updateMethod: 'PATCH',
  arrayFormat: 'brackets',
};

export default Settings;