import React from "react";
import {
  Box,
  Container,
  Grid,
  ThemeProvider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import Header from "./header";
import theme from "../theme/theme";

const useStyles = makeStyles({
  mainContainerBox: {
    position: "absolute",
    left: "0",
    top: "0",
    bottom: "0",
    right: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  contentBox: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "66%",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "33%",
    },
    backgroundColor: "paper",
    border: "solid",
    borderColor: grey[300],
    borderRadius: "6px",
    borderWidth: "1px",
    padding: "15px",
    verticalAlign: "center",
  },
});

const EmailSent = () => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Grid container direction="column">
        <Header title="E-mail byl odeslán" />
      </Grid>
      <Box className={classes.mainContainerBox}>
        <Container className={classes.contentBox}>
          <Typography variant="body2" paragraph>
            Na zadanou adresu byla odeslána e-mailová zpráva, jejíž
            prostřednictvím si budete moci nastavit nové heslo. Následujte
            instrukce v ní obsažené.
          </Typography>
          <Typography variant="body2">Toto okno můžete nyní zavřít.</Typography>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default EmailSent;
