import { email, maxLength, minLength, regex, required } from "react-admin";

export const requiredInputValidator = required("Povinné pole");

export const emailInputValidator = [
  requiredInputValidator,
  email("Vstup neodpovídá platné e-mailové adrese"),
];

export const loginNameInputValidator = [
  requiredInputValidator,
  minLength(3, "Přihlašovací jméno musí obsahovat minimálně 3 znaky"),
  maxLength(50, "Přihlašovací jméno může obsahovat maximálně 50 znaků"),
  regex(/^[A-Za-z0-9._]{3,}$/),
];

export const passwordInputValidator = [
  minLength(6, "Heslo musí mít minimálně 6 znaků"),
  maxLength(50, "Heslo může mít maximálně 50 znaků"),
  regex(
    /[A-Z]/,
    "Heslo musí obsahovat minimálně jedno velké písmeno bez diakritiky"
  ),
  regex(
    /[a-z]/,
    "Heslo musí obsahovat minimálně jedno malé písmeno bez diakritiky"
  ),
  regex(/[0-9]/, "Heslo musí obsahovat minimálně jednu číslici"),
  regex(
    /[!@#$%^&*,.\\/?:;_\s]/,
    "Heslo musí obsahovat minimálně jeden z těchto znaků: !@#$%^&*,./?:;_ a mezeru"
  ),
];

export const passwordsMatch = (value, allValues) => {
  if (!value && !allValues.password) return undefined;
  return value === allValues.password
    ? undefined
    : { message: "Hesla se neshodují" };
};

export const confirmedPasswordInputValidator = [
  ...passwordInputValidator,
  passwordsMatch,
];

export const requiredPasswordInputValidator = [
  requiredInputValidator,
  ...passwordInputValidator,
];

export const requiredConfirmedPasswordInputValidator = [
  requiredInputValidator,
  ...confirmedPasswordInputValidator,
];

export const phoneNumberInputValidator = [
  requiredInputValidator,
  regex(
    /^(?:(?:\+|0{2})420)?\s?\d{3}\s?\d{3}\s?\d{3}$/,
    "Telefonní číslo nemá požadovaný tvar"
  ),
];

export const zipCodeInputValidator = [
  requiredInputValidator,
  regex(/^\d{3}\s?\d{2}$/, "Směrovací číslo nemá platný tvar"),
];

export const validFloat = (value) => {
  const number = Number(value);
  if (isNaN(number)) {
    return "Vstup není platné desetinné číslo";
  }
};

export const validLongitude = (value) => {
  const number = Number(value);
  if (isNaN(number) || number <= 12.0924 || number >= 18.8584) {
    return "Vstup neodpovídá platnému rozsahu souřadnic zeměpisné délky pro ČR";
  }

  return undefined;
};

export const longitudeInputValidator = [
  requiredInputValidator,
  validFloat,
  validLongitude,
];

export const validLatitude = (value) => {
  const number = Number(value);
  if (isNaN(number) || number <= 48.5545 || number >= 51.0455) {
    return "Vstup neodpovídá platnému rozsahu souřadnic zeměpisné šířky pro ČR";
  }

  return undefined;
};

export const latitudeInputValidator = [
  requiredInputValidator,
  validFloat,
  validLatitude,
];

export const positiveNumber = (value) => {
  const number = Number(value);
  if (isNaN(number)) {
    return "Vstup neodpovídá platnému číslu";
  } else if (number < 0) {
    return "Zadané číslo nesmí být záporné";
  }

  return undefined;
};

export const gpsAccuracyInputValidator = [
  requiredInputValidator,
  validFloat,
  positiveNumber,
];

export const handlesProtectedAnimalsInputValidator = (value, allValues) => {
  if (!value && !allValues.handlesGame) {
    return "Pokud Vaše instituce nezpracovává lovnou zvěř, je třeba mít aktivovánu tuto volbu, abyste mohli přijímat hlášení týkající se ostatní zvěře";
  }

  return undefined;
};
