import React from "react";
import { SvgIcon } from "@material-ui/core";

function RegionsIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M 7.4433594 0.46875 C 3.5733594 0.46875 0.44335938 3.59875 0.44335938 7.46875 C 0.44335938 12.718749 7.4433594 20.46875 7.4433594 20.46875 C 7.4433594 20.46875 9.2970184 18.403719 11.097656 15.693359 C 13.154014 19.783428 16.59375 23.599609 16.59375 23.599609 C 16.59375 23.599609 23.59375 15.849609 23.59375 10.599609 C 23.59375 6.7296098 20.46375 3.5996094 16.59375 3.5996094 C 15.542822 3.5996094 14.550176 3.836227 13.65625 4.25 C 12.491615 2.004223 10.150993 0.46875 7.4433594 0.46875 z M 7.4433594 4.96875 C 8.8233597 4.96875 9.9433594 6.08875 9.9433594 7.46875 C 9.9433594 8.84875 8.8233597 9.96875 7.4433594 9.96875 C 6.0633594 9.96875 4.9433594 8.84875 4.9433594 7.46875 C 4.9433594 6.08875 6.0633594 4.96875 7.4433594 4.96875 z M 16.59375 8.0996094 C 17.97375 8.0996094 19.09375 9.2196098 19.09375 10.599609 C 19.09375 11.979609 17.97375 13.099609 16.59375 13.099609 C 15.21375 13.099609 14.09375 11.979609 14.09375 10.599609 C 14.09375 9.2196098 15.21375 8.0996094 16.59375 8.0996094 z " />
    </SvgIcon>
  );
}

export default RegionsIcon;
