import React, { Fragment, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { PropTypes } from "prop-types";
import { useLocation } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Container,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  ThemeProvider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import grey from "@material-ui/core/colors/grey";
import Header from "./header";
import { apiURL } from "../constants";
import theme from "../theme/theme";
import { passwordInputValidator, passwordsMatch } from "../validators";

const useStyles = makeStyles({
  topLevelContainerBox: {
    position: "absolute",
    left: "0",
    top: "0",
    bottom: "0",
    right: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  passwordEntryForm: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "66%",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "33%",
    },
    backgroundColor: "paper",
    border: "solid",
    borderColor: grey[300],
    borderRadius: "6px",
    borderWidth: "1px",
    padding: "15px",
    verticalAlign: "center",
  },
  sendButtonContainerBox: {
    marginTop: "20px",
  },
  sendButton: {
    float: "right",
  },
});

const PasswordField = ({
  name,
  label,
  errorText,
  onChange,
  onBlur,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const revealPasswordIconButton = (
    <InputAdornment position="end">
      <IconButton
        aria-label="Nastavit viditelnost hesla"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
        onMouseDown={(event) => {
          event.preventDefault();
        }}
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  return errorText ? (
    <TextField
      required
      fullWidth
      type={showPassword ? "text" : "password"}
      name={name}
      label={label}
      onChange={onChange}
      onBlur={onBlur}
      error
      helperText={errorText}
      InputProps={{
        endAdornment: revealPasswordIconButton,
      }}
      {...props}
    />
  ) : (
    <TextField
      required
      fullWidth
      type={showPassword ? "text" : "password"}
      name={name}
      label={label}
      onChange={onChange}
      onBlur={onBlur}
      InputProps={{
        endAdornment: revealPasswordIconButton,
      }}
      {...props}
    />
  );
};
PasswordField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  errorText: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

const NewPassword = (props) => {
  const [firstRun, setFirstRun] = useState(true);

  const [firstPassword, setFirstPassword] = useState(undefined);
  const [firstPasswordError, setFirstPasswordError] = useState(undefined);

  const [secondPassword, setSecondPassword] = useState(undefined);
  const [secondPasswordError, setSecondPasswordError] = useState(undefined);

  const [serverErrorResponse, setServerErrorResponse] = useState(undefined);

  const [submitted, setSubmitted] = useState(false);
  const [done, setDone] = useState(false);

  // Parse the validation hash/token
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");

  const handleChange = (event) => {
    event.target.name === "password" && setFirstPassword(event.target.value);
    event.target.name === "passwordConfirm" &&
      setSecondPassword(event.target.value);

    setFirstPasswordError(undefined);
    setSecondPasswordError(undefined);
  };

  const handleSubmit = (event) => {
    const result = validatePasswords();
    if (result) {
      setSubmitted(true);
    }

    event.preventDefault();
  };

  const validatePassword = (password, errorSetter) => {
    var validator = null;
    for (validator of passwordInputValidator) {
      const result = validator(password);
      if (result !== undefined) {
        errorSetter(result.message);
        return false;
      }
    }

    return true;
  };

  const validatePasswords = () => {
    var result = validatePassword(firstPassword, setFirstPasswordError);
    if (result) {
      result =
        result && validatePassword(secondPassword, setSecondPasswordError);
    }
    if (result) {
      const matchResult = passwordsMatch(secondPassword, {
        password: firstPassword,
      });
      if (matchResult !== undefined) {
        setSecondPasswordError(matchResult.message);
        result = false;
      }
    }
    return result;
  };

  useEffect(() => {
    firstRun &&
      axios
        .post(apiURL + "/verifyhash", { hash: token })
        .catch((error) => {
          setServerErrorResponse(error.message);
        })
        .finally(() => setFirstRun(false));
  }, [firstRun, token]);

  useEffect(() => {
    if (submitted) {
      setSubmitted(false);
      axios
        .post(apiURL + "/newpassword", { hash: token, password: firstPassword })
        .then(() => {
          setDone(true);
        })
        .catch((error) => {
          setServerErrorResponse(error.message);
        })
        .finally(() => {
          setSubmitted(false);
        });
    }
  }, [submitted, firstPassword, token]);

  const classes = useStyles();
  return done ? (
    <Redirect to="/passwordchanged" />
  ) : (
    <ThemeProvider theme={theme}>
      <Grid container direction="column">
        <Header title="Nastavení nového hesla" />
      </Grid>
      <Box className={classes.topLevelContainerBox}>
        <Container className={classes.passwordEntryForm}>
          {serverErrorResponse === undefined ? (
            <Fragment>
              <Typography variant="body2" paragraph>
                Zadejte nové heslo do polí níže a poté klepněte na tlačítko{" "}
                <Typography variant="body2" display="inline" color="primary">
                  Odeslat
                </Typography>
                .
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container direction="row" justify="center" spacing={4}>
                  <Grid item xs={6}>
                    <PasswordField
                      name="password"
                      label="Nové heslo"
                      errorText={firstPasswordError}
                      onChange={handleChange}
                      onBlur={() =>
                        validatePassword(firstPassword, setFirstPasswordError)
                      }
                      {...props}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <PasswordField
                      name="passwordConfirm"
                      label="Nové heslo znovu"
                      errorText={secondPasswordError}
                      onChange={handleChange}
                      onBlur={() => validatePasswords()}
                      {...props}
                    />
                  </Grid>
                </Grid>
                <Box className={classes.sendButtonContainerBox}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.sendButton}
                  >
                    Odeslat
                  </Button>
                </Box>
              </form>
            </Fragment>
          ) : (
            <Typography variant="body2" style={{ color: "red" }}>
              {serverErrorResponse}
            </Typography>
          )}
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default NewPassword;
