import React from "react";
import {SaveButton, Toolbar} from "react-admin";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

// ExtendedToolbar removes the Delete button,
// which may not be applicable for certain
// types of resources, makes the use of the
// Save button optional, and allows display of
// a note that explains the fact.

const useStyles = makeStyles({
  extendedToolbar: {
    justifyContent: "space-between"
  },
  note: {
    flexGrow: 1,
  },
  noteLeft: {
    extend: "note",
    textAlign: "left"
  },
  noteRight: {
    extend: "note",
    textAlign: "left"
  },
});

const ExtendedToolbar = ({ noteText, showSave, ...rest}) => {
  const classes = useStyles();

  return (
    <Toolbar className={classes.extendedToolbar} {...rest}>
      {showSave && <SaveButton/>}
      {noteText && <Typography className={showSave ? classes.noteRight : classes.noteLeft}>{noteText}</Typography>}
    </Toolbar>
  );
}

export default ExtendedToolbar;